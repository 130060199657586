<template>
  <PageWithLayout :isBodyBg="false">
    <BoxView title="Badges small" :grid="100">
      <div class="group_badge">
        <Badge text="default" badgeSize="small" />
        <Badge text="red" badgeStyle="red" badgeSize="small" />
        <Badge text="yellow" badgeStyle="yellow" badgeSize="small" />
        <Badge text="skyblue" badgeStyle="skyblue" badgeSize="small" />
        <Badge text="lightgreen" badgeStyle="lightgreen" badgeSize="small" />
        <Badge text="pink" badgeStyle="pink" badgeSize="small" />
        <Badge text="gray" badgeStyle="gray" badgeSize="small" />
        <Badge text="orange" badgeStyle="orange" badgeSize="small" />
        <Badge text="blue" badgeStyle="blue" badgeSize="small" />
        <Badge text="brown" badgeStyle="brown" badgeSize="small" />
        <Badge text="green" badgeStyle="green" badgeSize="small" />
        <Badge text="purple" badgeStyle="purple" badgeSize="small" />
      </div>
      <div class="group_badge">
        <Badge text="default" :badgeFill="true" badgeSize="small" />
        <Badge text="red" badgeStyle="red" :badgeFill="true" badgeSize="small" />
        <Badge text="yellow" badgeStyle="yellow" :badgeFill="true" badgeSize="small" />
        <Badge text="skyblue" badgeStyle="skyblue" :badgeFill="true" badgeSize="small" />
        <Badge text="lightgreen" badgeStyle="lightgreen" :badgeFill="true" badgeSize="small" />
        <Badge text="pink" badgeStyle="pink" :badgeFill="true" badgeSize="small" />
        <Badge text="gray" badgeStyle="gray" :badgeFill="true" badgeSize="small" />
        <Badge text="orange" badgeStyle="orange" :badgeFill="true" badgeSize="small" />
        <Badge text="blue" badgeStyle="blue" :badgeFill="true" badgeSize="small" />
        <Badge text="brown" badgeStyle="brown" :badgeFill="true" badgeSize="small" />
        <Badge text="green" badgeStyle="green" :badgeFill="true" badgeSize="small" />
        <Badge text="purple" badgeStyle="purple" :badgeFill="true" badgeSize="small" />
      </div>
    </BoxView>
    <BoxView title="Badges medium" :grid="100">
      <div class="group_badge">
        <Badge text="default" badgeSize="medium" />
        <Badge text="red" badgeStyle="red" badgeSize="medium" />
        <Badge text="yellow" badgeStyle="yellow" badgeSize="medium" />
        <Badge text="skyblue" badgeStyle="skyblue" badgeSize="medium" />
        <Badge text="lightgreen" badgeStyle="lightgreen" badgeSize="medium" />
        <Badge text="pink" badgeStyle="pink" badgeSize="medium" />
        <Badge text="gray" badgeStyle="gray" badgeSize="medium" />
        <Badge text="orange" badgeStyle="orange" badgeSize="medium" />
        <Badge text="blue" badgeStyle="blue" badgeSize="medium" />
        <Badge text="brown" badgeStyle="brown" badgeSize="medium" />
        <Badge text="green" badgeStyle="green" badgeSize="medium" />
        <Badge text="purple" badgeStyle="purple" badgeSize="medium" />
      </div>
      <div class="group_badge">
        <Badge text="default" :badgeFill="true" badgeSize="medium" />
        <Badge text="red" badgeStyle="red" :badgeFill="true" badgeSize="medium" />
        <Badge text="yellow" badgeStyle="yellow" :badgeFill="true" badgeSize="medium" />
        <Badge text="skyblue" badgeStyle="skyblue" :badgeFill="true" badgeSize="medium" />
        <Badge text="lightgreen" badgeStyle="lightgreen" :badgeFill="true" badgeSize="medium" />
        <Badge text="pink" badgeStyle="pink" :badgeFill="true" badgeSize="medium" />
        <Badge text="gray" badgeStyle="gray" :badgeFill="true" badgeSize="medium" />
        <Badge text="orange" badgeStyle="orange" :badgeFill="true" badgeSize="medium" />
        <Badge text="blue" badgeStyle="blue" :badgeFill="true" badgeSize="medium" />
        <Badge text="brown" badgeStyle="brown" :badgeFill="true" badgeSize="medium" />
        <Badge text="green" badgeStyle="green" :badgeFill="true" badgeSize="medium" />
        <Badge text="purple" badgeStyle="purple" :badgeFill="true" badgeSize="medium" />
      </div>
    </BoxView>
    <BoxView title="Badges large" :grid="100">
      <div class="group_badge">
        <Badge text="default" badgeSize="large" />
        <Badge text="red" badgeStyle="red" badgeSize="large" />
        <Badge text="yellow" badgeStyle="yellow" badgeSize="large" />
        <Badge text="skyblue" badgeStyle="skyblue" badgeSize="large" />
        <Badge text="lightgreen" badgeStyle="lightgreen" badgeSize="large" />
        <Badge text="pink" badgeStyle="pink" badgeSize="large" />
        <Badge text="gray" badgeStyle="gray" badgeSize="large" />
        <Badge text="orange" badgeStyle="orange" badgeSize="large" />
        <Badge text="blue" badgeStyle="blue" badgeSize="large" />
        <Badge text="brown" badgeStyle="brown" badgeSize="large" />
        <Badge text="green" badgeStyle="green" badgeSize="large" />
        <Badge text="purple" badgeStyle="purple" badgeSize="large" />
      </div>
      <div class="group_badge">
        <Badge text="default" :badgeFill="true" badgeSize="large" />
        <Badge text="red" badgeStyle="red" :badgeFill="true" badgeSize="large" />
        <Badge text="yellow" badgeStyle="yellow" :badgeFill="true" badgeSize="large" />
        <Badge text="skyblue" badgeStyle="skyblue" :badgeFill="true" badgeSize="large" />
        <Badge text="lightgreen" badgeStyle="lightgreen" :badgeFill="true" badgeSize="large" />
        <Badge text="pink" badgeStyle="pink" :badgeFill="true" badgeSize="large" />
        <Badge text="gray" badgeStyle="gray" :badgeFill="true" badgeSize="large" />
        <Badge text="orange" badgeStyle="orange" :badgeFill="true" badgeSize="large" />
        <Badge text="blue" badgeStyle="blue" :badgeFill="true" badgeSize="large" />
        <Badge text="brown" badgeStyle="brown" :badgeFill="true" badgeSize="large" />
        <Badge text="green" badgeStyle="green" :badgeFill="true" badgeSize="large" />
        <Badge text="purple" badgeStyle="purple" :badgeFill="true" badgeSize="large" />
      </div>
    </BoxView>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import BoxView from '@lemontree-ai/lemontree-admin-common-front/components/common/boxView/BoxView';
import Badge from '@lemontree-ai/lemontree-admin-common-front/components/common/badge/Badge';

export default {
  name: 'Badges',
  components:{
    PageWithLayout,
    BoxView,
    Badge,
  },
}
</script>

<style scoped>
.box_view::v-deep .group_badge + .group_badge{margin-top:20px}
/* badge */
.group_badge span{margin:4px}
</style>